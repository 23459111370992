<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <modal-add-category
      :store-module-name="STORE_MODULE_NAME"
      :is-modal-add-category-active.sync="isModalAddCategoryActive"
      :store-module-name-service-area="STORE_MODULE_NAME_SERVICE_AREA"
      :show.sync="show"
      :data="modalAddCategoryData"
    />
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-row>
        <b-col>
          <promotion />
        </b-col>
      </b-row>

    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BButton, BCardText, BRow, BCol,
  BImg,
  BTab,
  BTabs,
  BAvatar,
  BFormInput, BOverlay,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/hero/partnerPromotion'
import storeModuleServiceArea from '@/store/services/hero/serviceArea'
import ModalAddCategory from './components/ModalAddCategory.vue'
import Promotion from './Promotion.vue'

const STORE_MODULE_NAME = 'partnerPromotion'
const STORE_MODULE_NAME_SERVICE_AREA = 'serviceArea'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BTab,
    // eslint-disable-next-line vue/no-unused-components
    BTabs,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BOverlay,
    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    ModalAddCategory,
    Promotion,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      isModalAddCategoryActive: false,
      modalAddCategoryData: {},
      show: false,
      perPageOptions,
    }
  },
  computed: {
    respData() {
      return store.state[STORE_MODULE_NAME].respDataCategory != null ? store.state[STORE_MODULE_NAME].respDataCategory.data : []
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respDataCategory != null ? store.state[STORE_MODULE_NAME].respDataCategory.max : 0
    },
    // eslint-disable-next-line vue/return-in-computed-property
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    if (!store.hasModule(STORE_MODULE_NAME_SERVICE_AREA)) store.registerModule(STORE_MODULE_NAME_SERVICE_AREA, storeModuleServiceArea)

    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME_SERVICE_AREA}/get`, {
        currentPage: 0,
        pageSize: 0,
        searchQuery: '',
      })
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    if (store.hasModule(STORE_MODULE_NAME_SERVICE_AREA)) store.unregisterModule(STORE_MODULE_NAME_SERVICE_AREA)
  },
  setup() {
    return { STORE_MODULE_NAME, STORE_MODULE_NAME_SERVICE_AREA }
  },
  methods: {
    modalAddCategoryEdit(item) {
      this.modalAddCategoryData = item
      this.isModalAddCategoryActive = true
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .v-select-custom {
    display: inline-block;
    width: 50;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .b-col-custom {
    margin-top: 0.5rem;
  }
  </style>

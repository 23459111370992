// eslint-disable-next-line no-unused-vars
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respDataPartner: null,
    respDataService: null,
    respDataServiceSelected: null,
    respData: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
    ADD(state, payload) {
      if (state.respData != null) {
        state.respData.data.push(payload)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max + 1
      } else {
        state.respData.data = [payload]
        state.respData.max = 1
      }
    },
    UPDATE(state, payload) {
      const productIndex = state.respData.data.findIndex(p => p._id === payload._id)
      Object.assign(state.respData.data[productIndex], payload)
    },
    REMOVE(state, id) {
      if (state.respData != null) {
        const ItemIndex = state.respData.data.findIndex(p => p._id === id)
        state.respData.data.splice(ItemIndex, 1)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max - 1
      }
    },
    SWITCH_CHANGE(state, item) {
      const ItemIndex = state.respData.data.findIndex(p => p._id === item.id)
      if (ItemIndex > -1) {
        const nameChild = item.name.split('.')
        console.log(nameChild)
        if (nameChild.length === 3) {
          state.respData.data[nameChild[0]][nameChild[1]][nameChild[2]] = item.status
        } else if (nameChild.length === 2) {
          state.respData.data[nameChild[0]][nameChild[1]] = item.status
        } else {
          state.respData.data[item.name] = item.status
        }
      }
    },
    GET_PARTNER(state, payload) {
      state.respDataPartner = payload
    },
    GET_SERVICE(state, payload) {
      state.respDataService = payload
    },
    GET_SERVICE_SELECTED(state, payload) {
      state.respDataServiceSelected = payload
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    getCategory({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/partnerPromotion/getCategory?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET_CATEGORY', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    addCategory({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/hero/partnerPromotion/addCategory', item)
          .then(response => {
            commit('ADD_CATEGORY', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    updateCategory({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/partnerPromotion/updateCategory', item)
          .then(response => {
            commit('UPDATE_CATEGORY', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    deleteCategory({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.delete(`/api/web/application/hero/partnerPromotion/deleteCategory?id=${item}`)
          .then(response => {
            commit('REMOVE_CATEGORY', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/partnerPromotion/get?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    add({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/hero/partnerPromotion/add', item)
          .then(response => {
            commit('ADD', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    update({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/partnerPromotion/update', item)
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    delete({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.delete(`/api/web/application/hero/partnerPromotion/delete?id=${item}`)
          .then(response => {
            commit('REMOVE', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getPartner({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/partnerPromotion/getPartner?pageSize=${item.pageSize}&currentPage=${item.currentPage}&serviceType=${item.serviceType}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET_PARTNER', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getService({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/partnerPromotion/getService?id=${item.id}`)
          .then(response => {
            commit('GET_SERVICE', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getServiceSelected({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/partnerPromotion/getServiceSelected?id=${item.id}`)
          .then(response => {
            commit('GET_SERVICE_SELECTED', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    updateServiceSelected({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/partnerPromotion/updateServiceSelected', item)
          .then(response => {
            // commit('GET_SERVICE_SELECTED', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}

<template>
  <b-modal
    :visible="isModalAddCategoryActive"
    :title="$t('Category')"
    ok-title="Accept"
    @change="(val) => $emit('update:is-modal-add-category-active', val)"
  >
    <validation-observer ref="formData">
      <b-form class="p-2">

        <b-row class="mt-1">
          <b-col cols="12">
            <b-form-group
              :label="$t('Image')"
              label-for="register-image"
            >
              <!-- media -->
              <b-media no-body>
                <b-media-aside>
                  <b-link>
                    <b-img
                      v-if="image"
                      ref="previewEl"
                      rounded
                      :src="image"
                      height="80"
                    />
                    <b-img
                      v-else
                      ref="previewEl"
                      :src="require('@/assets/images/blank/no_image.png')"
                      rounded
                      height="80"
                    />
                  </b-link>
                  <!--/ avatar -->
                </b-media-aside>

                <b-media-body class="mt-75 ml-75">
                  <!-- upload button -->
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="$refs.refInputEl.$el.click()"
                  >
                    {{ $t("Upload") }}
                  </b-button>
                  <b-form-file
                    ref="refInputEl"
                    accept=".webp, .jpeg, .jpg, .png, .gif"
                    :hidden="true"
                    plain
                    @change="inputImageRenderer"
                  />
                  <!--/ upload button -->

                  <!-- reset -->
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="resetImage"
                  >
                    {{ $t("Reset") }}
                  </b-button>
                  <!--/ reset -->
                  <b-card-text>{{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG. {{ $t('Max size of') }} 300KB</b-card-text>
                </b-media-body>
              </b-media>
              <!--/ media -->
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-card-text>{{ $t("Name") }}</b-card-text>
          </b-col>
          <b-col
            v-for="(item , index) in language"
            :key="index+'name'"
            cols="12"
          >
            <!-- firstName -->
            <!-- firstName -->
            <b-form-group
              :label="item.name"
              :label-for="item.name+'name'"
            >
              <validation-provider
                #default="{ errors }"
                :name="item.name+'name'"
                :vid="item.name+'name'"
              >
                <b-form-input
                  v-if="name[index]"
                  :id="item.name+'name'"
                  :name="item.name+'name'"
                  :value="name[index]['value']"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="item.name"
                  @input="inputLanguage($event,item.lang ,index, 'name')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-card-text>{{ $t("Detail") }}</b-card-text>
          </b-col>
          <b-col
            v-for="(item , index) in language"
            :key="index+'detail'"
            cols="12"
          >
            <!-- firstName -->
            <!-- firstName -->
            <b-form-group
              :label="item.name"
              :label-for="item.name+'detail'"
            >
              <validation-provider
                #default="{ errors }"
                :name="item.name+'detail'"
                :vid="item.name+'detail'"
              >
                <b-form-input
                  v-if="detail[index]"
                  :id="item.name+'detail'"
                  :name="item.name+'detail'"
                  :value="detail[index]['value']"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="item.name"
                  @input="inputLanguage($event,item.lang ,index, 'detail')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              :label="$t('Service Area')"
              label-for="register-serviceArea"
            >
              <validation-provider
                #default="{ errors }"
                name="serviceArea"
                vid="serviceArea"
                rules=""
              >
                <v-select
                  id="register-serviceArea"
                  v-model="serviceAreaId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="serviceAreaData"
                  :reduce="(serviceAreaData) => serviceAreaData._id"
                  class="select-size-lg"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider></b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <div class="w-100">

        <div
          class="float-right"
        >

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            size="sm"
            class="mr-2"
            @click="validationForm"
          >
            {{ $t('Accept') }}
            <feather-icon
              icon="ShoppingBagIcon"
              size="12"
            />
          </b-button>

        </div>

      </div>
    </template>
  </b-modal>
</template>

<script>

import {
  BMedia,
  BMediaAside,
  BLink,
  BImg,
  BMediaBody,
  BFormFile,
  BModal,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BCardText,
  BFormGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BMedia,
    BLink,
    BImg,
    BMediaBody,
    BFormFile,
    BMediaAside,
    BModal,
    BForm,
    BCardText,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isModalAddCategoryActive',
    event: 'update:is-modal-add-category-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    storeModuleNameServiceArea: {
      type: String,
      required: true,
    },
    isModalAddCategoryActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      image: '',
      name: [],
      detail: [],
      serviceAreaId: null,
      required,
    }
  },
  computed: {
    language() {
      return store.state.appConfig.systemConfig ? store.state.appConfig.systemConfig.language : []
    },
    serviceAreaData() {
      return store.state[this.storeModuleNameServiceArea].respData != null ? store.state[this.storeModuleNameServiceArea].respData.data : []
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

  },
  watch: {
    isModalAddCategoryActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.initValues()
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
          if (this.detail.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.detail.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.detail.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
        } else {
          const {
            _id, image, name, detail, serviceAreaId,
          } = this.data
          this.dataId = _id
          this.image = image
          this.name = name
          this.detail = detail
          this.serviceAreaId = serviceAreaId
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.name.length; index += 1) {
              const indexLanguage = this.language.findIndex(element => element.lang === this.name[index].lang)
              if (indexLanguage < 0) {
                this.name.splice(index, 1)
              }
            }
          }
          if (this.detail.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.detail.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.detail.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.detail.length; index += 1) {
              const indexLanguage = this.language.findIndex(element => element.lang === this.detail[index].lang)
              if (indexLanguage < 0) {
                this.detail.splice(index, 1)
              }
            }
          }
        }
      }
    },
  },
  methods: {
    inputLanguage(input, lang, index, type) {
      if (type === 'name') {
        if (this.name[index].lang === lang) {
          this.name[index].value = input
        }
      } else if (type === 'detail') {
        if (this.detail[index].lang === lang) {
          this.detail[index].value = input
        }
      }
    },
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 300000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 300KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImage() {
      this.image = ''
    },
    initValues() {
      this.dataId = null
      this.image = ''
      this.name = []
      this.detail = []
      this.serviceAreaId = null
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            image: this.image,
            name: this.name,
            detail: this.detail,
            serviceArea: this.serviceAreaId,
          }
          this.$emit('update:show', true)
          if (this.dataId !== null) {
            store
              .dispatch(`${this.storeModuleName}/updateCategory`, obj)
              .then(result => {
                this.$emit('update:is-modal-add-category-active', false)
                this.$emit('update:show', false)
                console.log('fetch Success : ', result)
                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          } else {
            delete obj.id
            store
              .dispatch(`${this.storeModuleName}/addCategory`, obj)
              .then(result => {
                this.$emit('update:is-modal-add-category-active', false)
                this.$emit('update:show', false)
                console.log('fetch Success : ', result)
                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          }
        }
      })
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
